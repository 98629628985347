<template>
  <sign-page
    title-text="授权管理"
    :request="request"
    table-size="large"
    :table-actions="tableActions"
    :title-menus="[{key: 'add_all', label: '新增'}]"
    @clickTitleMenu="clickTitleMenu"
    @tableAction="tableAction"
    :column-list="columnList"
    :table-actions-fixed="true"
    :tableActionsWidth="100"
    :need-data-file="true">
    <fm-modal :mask-closable="false" v-model="openForm" v-if="openForm" width="1000px" theme="mh-blackt">
      <div class="content worker-data">
        <fm-title title-text="授权内容">
          <div style="display: flex;align-items: center;justify-content: flex-end;width: 100%;">
            <span style="margin-right: 1rem;">所属员工</span>
            <fm-select absolute filterable :clearable="!chooseData.id" v-model="chooseData.workerId" :disabled="chooseData.id && true">
              <fm-option v-for="item in workerList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </div>
        </fm-title>
        <div class="a-p-div a-p-div-p">
          <div class="a-p-item" v-for="itemP in authProjectForm" :key="itemP.id">
            <div class="a-p-label a-p-label-1">{{itemP.name}}</div>
            <div class="a-p-div" v-if="itemP.child && itemP.child.length > 0 && itemP.child[0].child">
              <div class="a-p-item" v-for="itemC in (itemP.child || [])" :key="itemC.id">
                <div class="a-p-label a-p-label-2">{{itemC.name}}</div>
                <div class="a-p-div-choose">
                  <fm-checkbox style="margin-right: 1rem" v-model="itemD.choose" size="small" :label="itemD.name" v-for="itemD in (itemC.child || [])" :key="itemD.id"></fm-checkbox>
                </div>
              </div>
            </div>
            <div class="a-p-div-choose" v-else>
              <fm-checkbox v-model="itemD.choose" style="margin-right: 1rem" size="small" :label="itemD.name" v-for="itemD in (itemP.child || [])" :key="itemD.id"></fm-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer-btns" style="margin-top: 1rem" v-loading="loading">
        <fm-btn @click="save">保存</fm-btn>
        <fm-btn @click="openForm = false">取消</fm-btn>
      </div>
    </fm-modal>
  </sign-page>
</template>

<script>
import {
  workerAuthRequest as request
} from '../api'

import configData from './config'

import {
  loadData,
  getSelectWorkerList,
  loadSelectWorkerList
} from '../auth_lib'

export default {
  data () {
    return {
      request: {
        get: this.loadData,
        del: request.del
      },
      openForm: false,
      authProject: configData.authProject,
      chooseData: {},
      pageVm: null,
      authProjectForm: []
    }
  },
  computed: {
    workerList () {
      return getSelectWorkerList(this)
    },
    tableActions () {
      return [{
        key: 'edit_all',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
    },
    columnList: {
      get () {
        let data = [{
          title: '员工姓名',
          sort: true,
          fixed: 'left',
          field: 'workerName'
        },
        {
          title: '身份证号码',
          fixed: 'left',
          sort: true,
          field: 'idNo'
        }]
        this.authProject.forEach((v) => {
          data.push({
            width: v.name === '手术操作（级别）授权' ? 200 : null,
            title: v.name,
            filterRange: true,
            sort: true,
            dataType: Number,
            field: 'count_' + v.id
          })
        })
        return data
      }
    }
  },
  methods: {
    async save () {
      if (!this.chooseData.workerId) {
        this.$notice.info({
          title: '系统提示',
          desc: '请选择所属员工'
        })
        return
      }
      this.loading = true
      this.chooseData.authData = this.getAuthData().join(',')
      if (this.chooseData.id) {
        await request.update(this.chooseData.id, this.chooseData)
        this.$notice.success({
          title: '系统提示',
          desc: '授权新增成功'
        })
      } else {
        await request.add(this.chooseData)
        this.$notice.success({
          title: '系统提示',
          desc: '授权修改成功'
        })
      }
      this.openForm = false
      this.pageVm.loadData()
    },
    tableAction (parm, pVm) {
      this.chooseData = JSON.parse(JSON.stringify(parm.data))
      this.authProjectForm = this.dealAuthProjectForm(this.chooseData.authData ? this.chooseData.authData.split(',') : [])
      this.loading = false
      this.pageVm = pVm
      this.openForm = true
    },
    dealAuthProjectForm (authData) {
      let data = JSON.parse(JSON.stringify(this.authProject))
      data.forEach((v) => {
        v.choose = authData.includes(String(v.id))
        if (v.child) {
          v.child.forEach(v2 => {
            v2.choose = authData.includes(String(v2.id))
            if (v2.child) {
              v2.child.forEach(v3 => {
                v3.choose = authData.includes(String(v3.id))
              })
            }
          })
        }
      })
      return data
    },
    getAuthData () {
      let data = []
      this.authProjectForm.forEach((v) => {
        if (v.choose) {
          data.push(v.id)
        }
        if (v.child) {
          v.child.forEach(v2 => {
            if (v2.choose) {
              data.push(v2.id)
            }
            if (v2.child) {
              v2.child.forEach(v3 => {
                if (v3.choose) {
                  data.push(v3.id)
                }
              })
            }
          })
        }
      })
      return data
    },
    clickTitleMenu (parm, pVm) {
      this.pageVm = pVm
      this.chooseData = {}
      this.authProjectForm = this.dealAuthProjectForm([])
      this.openForm = true
      this.loading = false
    },
    async loadData (parm) {
      let data = await loadData(request, this, parm)
      data = data.map((v) => this.dealData(v))
      console.log(data, 'loaddata')
      return data
    },
    dealData (dataItem) {
      this.authProject.forEach((v) => {
        let authData = dataItem.authData ? dataItem.authData.split(',') : []
        dataItem['count_' + v.id] = authData.filter(v1 => v1.indexOf(v.id + '_') === 0).length
      })
      return dataItem
    }
  },
  created () {
    if (this.$authFunsProxy.update || this.$authFunsProxy.add) {
      loadSelectWorkerList(this)
    }
  }
}
</script>

<style lang="less" scope>
.a-p-div-p {
  border-right: 1px solid #dcdee2;
  border-top: 1px solid #dcdee2;
}
.a-p-div {
  display: block;
  flex: 1;
  color: #515a6e;
}
.a-p-item {
  display: flex;
}
.a-p-div-choose {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 1rem;
  border-bottom: 1px solid #dcdee2;
  border-left: 1px solid #dcdee2;
  align-items: center;
  .fm-checkbox.fm-checkbox-small + .fm-checkbox.fm-checkbox-small {
    margin-left: 0;
  }
}
.a-p-label-1 {
  min-width: 220px;
  padding: 0.5rem 1rem;
  display: flex;
  border-bottom: 1px solid #dcdee2;
  border-left: 1px solid #dcdee2;
  align-items: center;
}
.a-p-label-2 {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  width: 170px;
  border-bottom: 1px solid #dcdee2;
  border-left: 1px solid #dcdee2;
}
</style>