export default {
  authProject: [{
    name: '处方授权',
    id: 1,
    child: ['普通药物处方', '非限制级抗生素处方', '限制级抗生素处方', '特殊级抗生素处方'].map((v, index) => {
      return {
        name: v,
        id: '1_' + index
      }
    })
  },
  {
    name: '手术操作（级别）授权',
    id: 2,
    child: ['一级手术', '二级手术', '三级手术', '四级手术'].map((v, index) => {
      return {
        name: v,
        id: '2_' + index
      }
    })
  },
  {
    name: '麻醉授权',
    id: 3,
    child: ['颈神经从阻滞麻醉', '臂神经从阻滞麻醉', '指神经从阻滞麻醉', '趾神经从阻滞麻醉', '硬脊膜外阻滞麻醉', '坐骨神经从阻滞麻醉', '蛛网膜下隙阻滞麻醉', '骶管阻滞麻醉', '腰硬联合麻醉', '全身麻醉（静脉麻醉）', '全身麻醉（吸入麻醉）', '局部麻醉', '其他'].map((v, index) => {
      return {
        name: v,
        id: '3_' + index
      }
    })
  },
  {
    name: '其他高风险诊疗技术资质审批授权',
    id: 6
  },
  {
    name: '检验、检查操作及报告授权',
    id: 4,
    child: [{
      name: '1.放射科',
      id: '4_9',
      child: ['X线摄像技术', '报告签发'].map((v, index) => {
        return {
          name: v,
          id: '4_9_' + index
        }
      })
    },
    {
      name: '2.心电图室',
      id: '4_10',
      child: ['心电图', '报告签发'].map((v, index) => {
        return {
          name: v,
          id: '4_10_' + index
        }
      })
    },
    {
      name: '3.超声科',
      id: '4_11',
      child: ['妇科', '产科（Ⅰ）', '产科（Ⅱ）', '产科（Ⅲ）', '产科（Ⅳ）', '腹部', '心脏', '浅表器官', '血管', '乳腺', '报告签发'].map((v, index) => {
        return {
          name: v,
          id: '4_11_' + index
        }
      })
    },
    {
      name: '4.临检类',
      id: '4_1',
      child: ['日常操作与维护', '报告签发'].map((v, index) => {
        return {
          name: v,
          id: '4_1_' + index
        }
      })
    },
    {
      name: '5.生化类',
      id: '4_2',
      child: ['日常操作与维护', '报告签发'].map((v, index) => {
        return {
          name: v,
          id: '4_2_' + index
        }
      })
    },
    {
      name: '6.免疫类',
      id: '4_3',
      child: ['日常操作与维护', '报告签发'].map((v, index) => {
        return {
          name: v,
          id: '4_3_' + index
        }
      })
    },
    {
      name: '7.体液类',
      id: '4_4',
      child: ['日常操作与维护', '报告签发'].map((v, index) => {
        return {
          name: v,
          id: '4_4_' + index
        }
      })
    },
    {
      name: '8.输血类',
      id: '4_5',
      child: ['日常操作与维护', '报告签发'].map((v, index) => {
        return {
          name: v,
          id: '4_5_' + index
        }
      })
    },
    {
      name: '9.化学发光',
      id: '4_6',
      child: ['日常操作与维护', '报告签发'].map((v, index) => {
        return {
          name: v,
          id: '4_6_' + index
        }
      })
    },
    {
      name: '10.临床基因 扩增检验',
      id: '4_7',
      child: ['日常操作与维护', '报告签发'].map((v, index) => {
        return {
          name: v,
          id: '4_7_' + index
        }
      })
    },
    {
      name: '11.微生物类',
      id: '4_8',
      child: ['日常操作与维护', '报告签发'].map((v, index) => {
        return {
          name: v,
          id: '4_8_' + index
        }
      })
    }]
  },
  {
    name: '母婴保健技术申请',
    id: 5,
    child: ['助产技术', '结扎手术', '终止妊娠手术', '产前诊断技术'].map((v, index) => {
      return {
        name: v,
        id: '5_' + index
      }
    })
  }]
}